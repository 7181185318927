<template>
  <main>
    <div class="pb">
      <Button icon="md-return-left" shape="circle" @click="$router.back()">返回</Button>
    </div>

    <Form ref="form" :model="form" :rules="validate" label-position="top">
      <Divider orientation="left">基本信息</Divider>
      <Row>
        <Col :span="9" :offset="3">
          <FormItem label="用户ID" prop="user_id">
            <Input :maxlength="45" disabled show-word-limit v-model="form.user_id"/>
          </FormItem>
          <FormItem label="题目类型" prop="question_type">
            <Select v-model="form.feedback_type" disabled class="w50">
              <Option :value="item.id" v-for="item in feedback_types" :key="item.id">{{ item.name }}</Option>
            </Select>
          </FormItem>
          <FormItem label="内容" prop="content">
            <Input type="textarea" style="width: 600px" disabled :rows="5" v-model="form.content"/>
          </FormItem>
           <FormItem label="图片">
            <upload-image name="user-feedback" disabled multiple v-model="form.imgs"/>
          </FormItem>
           <FormItem label="联系方式" prop="contact_way">
            <Input :maxlength="45" disabled show-word-limit v-model="form.contact_way"/>
          </FormItem>
        </Col>
      </Row>
    </Form>
  </main>
</template>

<script>
import UploadImage from "@/components/UploadImage";
export default {
  components: {
    UploadImage
  },
  data() {
    return {
      id: this.$route.params.id,
      form: {
        user_id: 0,
        feedback_type: 0,
        content: "",
        imgs: "",
        contact_way: ""
      },
      feedback_types: [{ id: 0, name: "优化建议" }, { id: 1, name: "使用问题" }, { id: 2, name: "投诉" }, { id: 3, name: "其他" }],
    };
  },
  created() {
    this.id && this.loadData();
  },
  methods: {
    loadData() {
      this.$http.get("/userFeedback/detail/id/" + this.id).then(res => {
        this.form = res;
      });
    }
  }
};
</script>